<template>
  <v-container>
    <h1>Partner Settings</h1>
    <v-card v-if="currentUser" rounded="xl" class="mt-4 pt-4 pb-4">
      <v-container
        style="max-width: 753px !important; margin: 0 auto;"
        fill-height
      >
        <v-row align="center" justify="center">
          <v-col align="center" justify="center" cols="12" md="4" sm="4">
            <v-avatar size="150">
              <img v-if="!currentUser.photoURL" src="@/assets/avatar.png" />
              <img v-else :src="currentUser.photoURL" />
            </v-avatar>
            <v-file-input
              style="margin-top: 32px;"
              accept="image/*"
              placeholder="Choose a photo"
              label="Photo"
              outlined
              rounded
              dense
              @change="uploadFile"
            ></v-file-input>
          </v-col>
          <!-- right -->
          <v-col>
            <v-col cols="12" md="12">
              <v-card-text
                class="title black--text pl-0"
                :style="$mq === 'mobile' ? '' : 'font-size: 2em !important;'"
                >{{ user.email }}</v-card-text
              >

              <v-text-field
                v-model="currentUser.name"
                label="Name"
                outlined
                rounded
                dense
              ></v-text-field>
              <v-text-field
                v-model="currentUser.contact"
                label="Contact Name"
                outlined
                rounded
                dense
              ></v-text-field>

              <v-text-field
                v-model="currentUser.phoneNumber"
                label="Phone Number"
                outlined
                rounded
                dense
                :rules="phoneRules"
              ></v-text-field>
              <v-text-field
                v-model="currentUser.address"
                label="Address"
                outlined
                rounded
                dense
              ></v-text-field>
              <v-text-field
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show2 = !show2"
                v-model="oldPassword"
                label="Old Password"
                outlined
                rounded
                dense
              ></v-text-field>

              <v-text-field
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                value="wqfasds"
                class="input-group--focused"
                @click:append="show2 = !show2"
                v-model="currentUser.password"
                label="Password"
                outlined
                rounded
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-row
                ><v-col cols="12" md="8"
                  ><div class="black--text font-weight-bold">
                    Receive text notifications
                  </div></v-col
                >
                <v-col cols="12" md="4"
                  ><v-switch
                    v-model="currentUser.receiveTextNotifications"
                    :label="currentUser.receiveTextNotifications ? 'on' : 'off'"
                    color="success"
                    :value="currentUser.receiveTextNotifications"
                    class="ma-0"
                    hide-details="hint"
                    :style="$mq === 'mobile' ? '' : 'float: right;'"
                  ></v-switch
                ></v-col>
              </v-row>
            </v-col>

            <v-card-actions style="justify-content: flex-end;">
              <v-chip v-if="indicate" color="grey" outlined label class="mr-4">
                <v-icon left>
                  mdi-checkbox-marked-outline
                </v-icon>
                Updated
              </v-chip>
              <v-btn
                :block="$mq === 'mobile'"
                color="primary"
                :loading="loading"
                @click.native="update"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-col>
          <v-alert dense type="error" dismissible v-if="error">
            {{ error }}</v-alert
          >
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import PartnerLayout from '@/layouts/PartnerLayout'
import { db, auth, storage } from '@/firebase'
export default {
  name: 'PartnerSettings',
  props: ['user'],
  data() {
    return {
      indicate: false,
      show2: false,
      loading: false,
      error: '',
      currentUser: null,
      oldPassword: null,
      phoneRules: [
        value => !!value || !value,
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          const pattern3 = /^\s*$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            pattern3.test(value) ||
            'Invalid phone number'
          )
        }
      ]
    }
  },
  created() {
    this.$emit(`update:layout`, PartnerLayout)
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    },
    indicate: function() {
      setTimeout(
        function() {
          this.indicate = false
        }.bind(this),
        2000
      )
    }
  },
  methods: {
    async update() {
      this.loading = true
      this.currentUser.displayName =
        this.currentUser.fname + ' ' + this.currentUser.lname
      try {
        await this.updateUser({
          name: this.currentUser.name,
          receiveTextNotifications: this.currentUser.receiveTextNotifications,
          phoneNumber: this.currentUser.phoneNumber,
          contact: this.currentUser.contact,
          address: this.currentUser.address
        })
      } catch (error) {
        this.error = error.message
      }

      if (this.currentUser.password) {
        if (this.oldPassword) {
          try {
            await auth
              .signInWithEmailAndPassword(
                this.currentUser.email,
                this.oldPassword
              )
              .then(async () => {
                await auth.currentUser.updatePassword(this.currentUser.password)
              })
          } catch (error) {
            this.error = error.message
          }
        } else {
          this.error = 'Old Password and New Password required'
        }
      }

      this.loading = false
    },
    async updateUser(data) {
      await auth.currentUser.updateProfile(data)
      await db.doc(`Partners/${this.currentUser.id}`).update({ ...data })
      this.indicate = true
    },
    uploadFile(file) {
      if (!file) return

      const reader = new FileReader()

      reader.onload = event => {
        this.currentUser.photoURL = event.target.result
      }

      reader.readAsDataURL(file)

      const storageRef = storage.ref()
      const photoRef = storageRef.child(
        `Partners/${this.currentUser.id}/photos/${file.name}`
      )
      const uploadTask = photoRef.put(file)

      uploadTask.on(
        'state_changed',
        snapshot => {
          console.log(snapshot.state)
        },
        error => {
          console.log(error)
        },
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
          db.doc(`Partners/${this.currentUser.id}`).set(
            { photoURL: downloadURL },
            { merge: true }
          )
        }
      )
      this.indicate = true
    }
  }
}
</script>
